/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({ description, lang, meta, title, nofollow }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            description
            author
            title
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const currentPage = typeof window !== 'undefined' ? window.location.href : '';

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={(title && `${title} | Zooticket DK`) || site.siteMetadata.title}
      titleTemplate={`%s`}
      link={[
        {
          rel: 'canonical',
          href: currentPage,
        },
      ]}
      script={[
        {
          src: 'https://cdn.splitbee.io/sb.js',
          async: true,
        },
      ]}
      meta={[
        {
          name: `robots`,
          content: nofollow ? 'noindex, nofollow' : 'index, follow',
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  );
}

SEO.defaultProps = {
  title: '',
  lang: 'da',
  meta: [],
  description: '',
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
};

export default SEO;
